import { ref } from "vue";
import fetchHotWordList, {IHotWord, THotWordList} from "global-common/apis/img-clippng/hot/word/list.ts";
import {defineStore} from "pinia";

export const useSearch = () => {
    const hotWords = ref<IHotWord[]>([]);
    const error = ref<Error>(null);
    const FETCH_HOT_WORD_LIST = async ( params:THotWordList ) => {
        try {
            const [ err, res ] = await fetchHotWordList(params);
            if ( err ) {
                error.value = err;
            } else {
                hotWords.value = res.data;
            }
            return [err,res];
        } catch ( e ) {
            return [ e, null ]
        }

    }
    return {
        hotWords,
        FETCH_HOT_WORD_LIST
    }
}

export const useStoreSearch = defineStore( `Search`, useSearch);