<script lang="ts" setup>
import { PropType } from "vue";
import { useRouter, RouteLocation } from "vue-router";
import type {IHotWord, THotWordList} from "global-common/apis/img-clippng/hot/word/list.ts";

const props = defineProps({
  theme: {
    type: Array as PropType<string[]>,
    default: []
  },
  /** 热词 */
  hotWords: {
    type: Array as PropType<IHotWord[]>,
    default: () => []
  },
  /** 标签 */
  label: {
    type: String,
    default: 'Popular Searches：'
  },
  link: {
    type: Function as PropType<(kw:string)=>string>,
    default: (kw:string) => kw
  }
});
const emits = defineEmits([`click`])
/** ******************** 组合式API ******************* */
const router = useRouter();
/** ****************** 组合式API End ****************** */

/** ****************** Methods Start ****************** */
const handleClickLink = ( index, item:IHotWord ) => {
  emits(`click`, {
    index,
    item: item
  })
}
/** ****************** Methods End ****************** */
</script>
<template>
  <div :class="[$style[`hot-words`], ...theme ]">
    <h5 :class="[$style[`label`], 'font-bold']">{{props.label}}</h5>
    <ol :class="$style[`list`]">
      <li v-for="(item, index) in props.hotWords"
          :key="item.show_word"
          :class="$style[`li`]"
      >
        <!--- 有链接 -->
        <a
           :class="[$style[`link`],$style[`word`] ]"
           :href="link(item.show_word)"
           :title="item.show_word"
           @click.prevent="handleClickLink(index, item)"
        >{{item.show_word}}</a>
      </li>
    </ol>
  </div>
</template>
<style lang="scss" module src="./hot-words.scss"></style>
