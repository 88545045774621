<script lang="ts" setup>
import { ref, defineOptions } from "vue";
import iconSearch from "@images/icon-search.svg";
/** 输入框类型 A 用于首页搜索等 */
defineOptions({
  name: 'SearchInputA',
})
const modelValue = defineModel<string>();
const props = defineProps({
  /** 主题 */
  theme: {
    type: String,
    default: 'theme-default'
  },
  /** 输入框提示 */
  placeholder: {
    type: String,
    default: '请输入搜索内容'
  },
  /** 正则 */
  regExp: {
    type: RegExp,
    default: null
  },
  /** 最大长度 */
  maxLength: {
    type: Number,
    default: 100
  },
})

const emits = defineEmits<{
  ( e: 'input', value: string): void;
  ( e: 'change', value: string): void;
  ( e: 'search', value: string): void;
  ( e: 'focus', value: string): void;
  ( e: 'blur', value: string): void;
}>();

const inputRef = ref<HTMLInputElement | null>(null);
const data = reactive({
  inputVal: ''
})

/** ***************** Methods ***************** */
// 输入框输入事件
const handleInput = (e: InputEvent) => {
  const value = (e.target as HTMLInputElement).value;
  data.inputVal = value.trim();
  emits('input', value);
}

const handleChange = (e: InputEvent) => {
  const value = (e.target as HTMLInputElement).value;
  emits('change', value);
}
const handleSearch = (value: string) => {
  emits('search', value);
}
const handleFocus = () => {
  emits('focus', data.inputVal);
}
const handleBlur = () => {
  emits('blur', data.inputVal);
}

const handleKeyUp = (e: KeyboardEvent) => {
  console.log(`key`, e.key,`keyCode`, e.keyCode);
  if (e.key === 'Enter') {
    handleSearch(data.inputVal);
  }
}

</script>
<template>
  <div :class="[$style[`search-input`], props.theme, `search-input-a`]">
    <div :class="[$style[`input-bk`],`search-input--bk`]">
        <input
          :class="[$style[`input`],`search-input--input`]"
          ref="inputRef"
          type="search"
          v-model="modelValue"
          :placeholder="props.placeholder"
          @focus="handleFocus"
          @blur="handleBlur"
          @input="handleInput"
          @change="handleChange"
          @keyup.enter="handleKeyUp"
          :maxlength="props.maxLength"
      >
    </div>
    <!-------------- 搜索按钮 -------------->
    <div :class="[$style[`search-btn`], `search-input--search`]"
         v-if="!$slots['search-btn']"
         @click="() => handleSearch(modelValue)"
    >
      <i :class="[$style[`icon`], `search-input--icon`]"></i>
      <inline-svg :src="iconSearch" ></inline-svg>
    </div>
    <slot name="search-btn" v-else ></slot>
    <!-------------- 搜索按钮结束 -------------->
  </div>
</template>
<style lang="scss" module src="./search-input-a.scss"></style>
