import {$axios, GcResponse} from "../../../helper";
import {ServerClippngImg} from "../../../../lib/constants/server";

export type THotWordList = {
    type: number,
}
// 首页模块列表
export type IHotWord = {
    id: number,
    show_word: string,
    search_word: string,
}

/** 拿热词
 * @see http://doc.bigbigwork.com/docs/hw/7943
 */
export default function fetchHotWordList (
    params: THotWordList
) {
    return $axios<GcResponse<IHotWord[]>> ({
        url: `${ServerClippngImg}/hot/word/list`,
        method: "GET",
        params: params
    })
}